import { useState, useMemo } from 'react';
import { parse } from 'json2csv';
import { jsPDF } from 'jspdf';
import domToImage from 'dom-to-image-more';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import Grid from '@material-ui/core/Grid';
import Box from '@mui/material/Box';
import DownloadMenuItem from '../../Atoms/DownloadMenuItem/DownloadMenuItem';
import ComponentLoader from '../../Atoms/ComponentLoader/ComponentLoader';

import CSVLogo from '../../../assets/logos/Csv.jsx';
import PNGLogo from '../../../assets/logos/Image.jsx';
import PDFLogo from '../../../assets/logos/Pdf.jsx';

import { DOWNLOAD_TYPES } from '../../../constants/props';
import { downloadFromUrl } from '../../../utils/downloadFile';
import useFeatureFlags from '../../../hooks/useFeatureFlags';

import styles from './DownloadDropdownMenu.module.scss';
import { CloseIcon } from '../../../assets/icons';

function DownloadDropdownMenu({
  className,
  targetId,
  fileName = 'blueocean',
  json,
  padding = 0,
  onClose,
  onSuccess,
}) {
  const FEATURE_FLAGS = useFeatureFlags();
  const [loading, setLoading] = useState(false);

  const menuItems = useMemo(() => {
    const result = [];

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.CSV) {
      result.push({
        logo: CSVLogo,
        name: 'CSV',
        type: DOWNLOAD_TYPES.CSV,
      });
    }

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.PDF) {
      result.push({
        logo: PDFLogo,
        name: 'PDF',
        type: DOWNLOAD_TYPES.PDF,
      });
    }

    if (FEATURE_FLAGS.GLOBAL.DOWNLOAD.PNG) {
      result.push({
        logo: PNGLogo,
        name: 'PNG',
        type: DOWNLOAD_TYPES.PNG,
      });
    }

    return result;
  }, [FEATURE_FLAGS]);

  async function handleDownloadTypeClick(menuItem) {
    setLoading(true);

    try {
      const targetNode = document.querySelector(`#${targetId}`);
      const uniqueFileName = `${fileName}_${dayjs().valueOf()}`;

      if (menuItem.type === DOWNLOAD_TYPES.PNG) {
        const pngScale = window.devicePixelRatio || 1; // Use device pixel ratio for better quality
        const width = targetNode.offsetWidth + 2 * padding;
        const height = targetNode.offsetHeight + 2 * padding;

        const blob = await domToImage.toBlob(targetNode, {
          style: {
            padding: `${padding}px`,
            backgroundColor: '#FFFFFF',
          },
          width: width * pngScale,
          height: height * pngScale,
          pngScale,
        });

        const url = URL.createObjectURL(blob);
        downloadFromUrl(url, `${uniqueFileName}.png`);
        URL.revokeObjectURL(url);
      }

      if (menuItem.type === DOWNLOAD_TYPES.PDF) {
        const pdfScale = 2; // Scale factor for higher resolution
        const width = targetNode.offsetWidth;
        const height = targetNode.offsetHeight;

        const dataUrl = await domToImage.toJpeg(targetNode, {
          quality: 1, // Maximum quality
          style: {
            transform: `scale(${pdfScale})`,
            transformOrigin: 'top left',
            backgroundColor: '#FFFFFF',
          },
          width: width * pdfScale,
          height: height * pdfScale,
        });

        // add extra padding to width and height
        const pdfWidth = width + 2 * padding;
        const pdfHeight = height + 2 * padding;

        // eslint-disable-next-line new-cap
        const pdf = new jsPDF({
          orientation: width > height ? 'landscape' : 'portrait',
          unit: 'px',
          format: [pdfWidth, pdfHeight],
        });

        pdf.addImage(dataUrl, 'JPEG', padding, padding, width, height);

        pdf.save(`${uniqueFileName}.pdf`);
      }

      if (menuItem.type === DOWNLOAD_TYPES.CSV) {
        const csv = parse(json);
        const blob = new Blob(['\ufeff', csv]);
        const url = URL.createObjectURL(blob);

        downloadFromUrl(url, uniqueFileName);
      }

      onSuccess?.();
    } catch (error) {
      alert('Download failed. Please try again or call customer support.');
      // eslint-disable-next-line no-console
      console.error('Error in handleDownloadTypeClick:', error);
    }

    setLoading(false);
  }

  return (
    <Box
      p={4}
      className={classNames(styles.DownloadDropdownMenu, className)}
      data-testid="download-dropdown-menu"
    >
      <Box className={styles.Header} mb={5}>
        <h1 className={styles.Title}>Download</h1>
        <CloseIcon className={styles.CloseIcon} onClick={() => onClose()} />
      </Box>

      {loading ? <ComponentLoader minHeight={144} /> : null}

      {!loading ? (
        <Grid container spacing={2}>
          {menuItems.map((item) => (
            <Grid item xs={6} key={item.type}>
              <DownloadMenuItem
                onClick={() => handleDownloadTypeClick(item)}
                LogoSVG={item.logo}
                name={item.name}
              />
            </Grid>
          ))}
        </Grid>
      ) : null}
    </Box>
  );
}

export default DownloadDropdownMenu;

DownloadDropdownMenu.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
  targetId: PropTypes.string.isRequired,
  json: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
  padding: PropTypes.number,
  fileName: PropTypes.string,
};
